const styles = (theme) => ({
  newsUpdate: {
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#6CA2D0",
    backgroundRepeat: "repeat",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    "& .title": {
      color: "#fff",
      textTransform: "uppercase",
      textAlign: "center",
    },
    "& .description": {
      color: "#fff",
      lineHeight: 2.13,
      fontWeight: 700,
      textAlign: "center",
      padding: theme.spacing(1, 0, 3.5, 0),
    },
    "& .subscribeWrapper": {
      borderRadius: 8,
      display: "flex",
      margin: "0 auto",
      marginBottom: theme.spacing(2),
      width: 570,
      border: "2px solid #fff",
      "&:hover": {
        borderColor: "#9ad455",
      },
      "&:focus-within": {
        borderColor: "#9ad455",
        outline: 0,
        outline: "none",
      },
      [theme.breakpoints.down("900px")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
        padding: theme.spacing(2, 2, 2),
        width: "100%",
      },
      "& .input": {
        flex: 1,
        fontFamily: "Raleway,Assistant",
        fontSize: 21,
        fontWeight: 900,
        padding: "0px 24px",
        [theme.breakpoints.down("sm")]: {
          padding: "0px 20px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "0px 16px",
        },
        "& input": {
          color: "#4c4c78",
          backgroundColor: "transparent",
          "&::placeholder": {
            color: "#4c4c78",
            opacity: 0.38,
          },
        },
      },
      "& .subButton": {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          marginTop: theme.spacing(2),
        },
      },
    },
    "& .privacyText p": {
      fontSize: 14,
      lineHeight: 2.13,
      fontWeight: 700,
      color: "#fff",
      textAlign: "center",
      "& span": {
        textDecoration: "underline",
      },
      "& a": {
        color: "#fff",
      },
    },
  },
});

export default styles;
