// pages/_app.js
import "../public/static/lightbox-react/style.css";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Client } from "../prismic1";
import { LayoutProvider } from "../utils/layout-context";
import Layout from "../components/Layout";
import "lightbox-react/style.css";
import theme from "../theme";
import { LocalityProvider } from "../store/locality-settings-context";
import { BasketProvider } from "../utils/basket-context";
import { getLanguage, getExtensionLanguage } from "../lib/getLanguage";
import TagManager from "react-gtm-module";
import getConfig from "next/config";
import { StoreProvider } from "easy-peasy";
import store from "../store/vfi-easy-peasy-store";
import { ThemeChangeProvider } from "../utils/theme-change-context";
import { useRouter } from "next/router";
import { AnimatePresence } from "framer-motion";

export default function MyApp(appProps) {
  const recaptchaKey = getConfig().publicRuntimeConfig.recaptchaSiteKey;
  const analytics = getConfig().publicRuntimeConfig.analytics;
  const { Component, pageProps, props } = appProps;
  const [language, setLanguage] = useState("en-us");
  const [country, setCountry] = useState("");
  const [basket, setBasket] = useState([]);
  theme.direction = props.language === "he" ? "rtl" : "ltr";
  const router = useRouter();
  const themeChange = useState(theme);

  // this block handles the (extreme!) edge case where a user changes to hebrew on the home page then clicks the back button
  // their selected language will still be Hebrew but at least the English page displayed will render in LTR
  if (
    themeChange[0] &&
    themeChange[0].direction &&
    themeChange[0].direction !== theme.direction
  ) {
    themeChange[1](theme);
  }

  useEffect(() => {
    // Remove the server-side injected CSS.

    TagManager.initialize({
      gtmId: analytics.googleTagManager.id,
      auth: analytics.googleTagManager.auth,
      preview: analytics.googleTagManager.preview,
    });

    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.Fragment>
      <StoreProvider store={store}>
        <Head>
          <title>VFI</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
        </Head>
        <LayoutProvider value={props.layout.data}>
          <ThemeChangeProvider value={themeChange}>
            <ThemeProvider theme={themeChange[0]}>
              <BasketProvider
                value={{
                  basket,
                  setBasket,
                }}
              >
                <LocalityProvider
                  value={{ setLanguage, language, setCountry, country }}
                >
                  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                  <CssBaseline />
                  <AnimatePresence
                    mode="wait"
                    onExitComplete={() => window.scrollTo({ top: 0 })}
                  >
                    <div
                      dir={themeChange[0].direction}
                      key={router.asPath.split("?")[0]}
                    >
                      <Layout
                        locale={
                          appProps &&
                          appProps.props &&
                          getExtensionLanguage(appProps.props.language)
                        }
                      >
                        <Component {...pageProps} />
                      </Layout>
                    </div>
                  </AnimatePresence>
                </LocalityProvider>
              </BasketProvider>
            </ThemeProvider>
          </ThemeChangeProvider>
        </LayoutProvider>
      </StoreProvider>
    </React.Fragment>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const client = Client();
  const languageObject = getLanguage(appContext.router.locale);
  const language = await languageObject.prismicLanguage.toLowerCase();
  const layout = (await client.getSingle("layout", { lang: language })) || {};
  return {
    props: {
      layout,
      language,
    },
  };
};

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
