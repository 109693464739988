import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import InputBase from "@material-ui/core/InputBase";
import style from "./style";
import { useLayout } from "../../utils/layout-context";
import { useRouter } from "next/router";
import MuiAlert from "@material-ui/lab/Alert";
import CheckRounded from "@material-ui/icons/CheckRounded";
import WarningOutlined from "@material-ui/icons/WarningOutlined";
import ErrorRounded from "@material-ui/icons/ErrorRounded";
import appConstants from "../../utils/constants/vfi-constants";
import { motion } from "framer-motion";
import {
  vSlideBottomFade,
  vSlideRightFade,
  vTextFade,
} from "../../utils/animations";

const NewsUpdate = ({ classes }) => {
  const { layout } = useLayout();
  const locale = useRouter().locale;
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [clearInput, setClearInput] = useState(false);

  const send = async (email) => {
    if (emailValid) {
      let response = await fetch("api/contact/mailing-list", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, twoLetterLanguageCode: locale }),
      });

      if (response.ok) {
        setClearInput(!clearInput);
        setSuccess(true);
        setError(false);
      } else {
        setSuccess(false);
        setError(true);
      }
    } else {
      setSnackbarOpen(true);
      setSuccess(false);
      setError(false);
    }
  };

  const emailValidation = (e) => {
    setEmailValid(appConstants.emailRegex.test(e.target.value));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setSnackbarOpen(false);
    setError(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.newsUpdate}>
      <Container>
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            delay: 0.2,
            duration: 0.8,
          }}
        >
          <Typography variant="h3" className="title">
            {RichText.asText(layout.subscribe_title)}
          </Typography>
        </motion.div>
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            delay: 0.4,
            duration: 0.8,
          }}
        >
          <Typography variant="subtitle2" className="description">
            {RichText.asText(layout.subscribe_description)}
          </Typography>
        </motion.div>
        <motion.div
          variants={vSlideRightFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
        >
          <form
            onSubmit={(formData) => {
              formData.preventDefault();
              send(email);
            }}
          >
            <Paper className="subscribeWrapper">
              <InputBase
                key={clearInput}
                onChange={(e) => {
                  setEmail(e.target.value);
                  emailValidation(e);
                }}
                className="input"
                type="email"
                placeholder={RichText.asText(layout.subscribe_placeholder)}
              />
              <Button
                color="secondary"
                variant="contained"
                size="large"
                className="subButton"
                aria-label="directions"
                type="submit"
              >
                {RichText.asText(layout.subscribe_button_text)}
              </Button>
            </Paper>
          </form>
        </motion.div>
        <motion.div
          variants={vSlideBottomFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
        >
          <div className="privacyText">
            {RichText.render(layout.privacy_text)}
          </div>
        </motion.div>
      </Container>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          icon={<CheckRounded fontSize="inherit" />}
          onClose={handleClose}
          severity="success"
        >
          {RichText.asText(layout.subscribe_success_message)}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen && !success && !error}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          icon={<WarningOutlined fontSize="inherit" />}
          onClose={handleClose}
          severity="warning"
        >
          {RichText.asText(layout.subscribe_email_error_message)}
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          icon={<ErrorRounded fontSize="inherit" />}
          onClose={handleClose}
          severity="error"
        >
          {RichText.asText(layout.subscribe_error_message)}
        </Alert>
      </Snackbar>
    </div>
  );
};

NewsUpdate.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(NewsUpdate);
